module.exports = {
    siteUrl: `https://kamilwysocki.com`, // Site domain. Do not include a trailing slash!

    postsPerPage: 200, // Number of posts shown on paginated pages (changes this requires sometimes to delete the cache)

    siteTitleMeta: `Kamil Wysocki - fotografia. Fotograf Gdańsk, Trójmiasto. | kamilwysocki.com`, // This allows an alternative site title for meta data for pages.
    siteDescriptionMeta: `Kamil Wysocki - fotografia. Sesje zdjęciowe, par, sesja rodzinna, w plenerze, blog, zdjęcia, historie, ludzie. Gdańsk, Gdynia, Sopot, Trójmiasto.`, // This allows an alternative site description for meta data for pages.

    shareImageWidth: 1000, // Change to the width of your default share image
    shareImageHeight: 523, // Change to the height of your default share image

    shortTitle: `kamilwysocki.com`, // Used for App manifest e.g. Mobile Home Screen
    siteIcon: `favicon.png`, // Logo in /static dir used for SEO, RSS, and App manifest
    backgroundColor: `#fff`, // Used for Offline Manifest
    themeColor: `#fff`, // Used for Offline Manifest
}
